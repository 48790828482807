export function array_repeat<T>(arr: T[], n: number) {
  let output: T[] = [];
  for (let i = 0; i < n; i++) {
    output = [...output, ...arr];
  }
  return output;
}

type Indices<L extends number, T extends number[] = []> = T["length"] extends L
  ? T[number]
  : Indices<L, [T["length"], ...T]>;

type LengthAtLeast<T extends readonly any[], L extends number> = Pick<
  Required<T>,
  Indices<L>
>;

/**
 * @public
 * TypeScript type narrowing assert helper function to help with noUncheckedIndexedAccess.
 * Only usable on small array index checking (0-4). If you need to check a larger
 * array, please review your implementation and use a loop instead.
 *
 * @example
 * const names = ["John", "Doe"];
 * if (assertLengthAtLeast(names, 2)) {
 *  console.log(names[1]); // no error
 * }
 *
 */
export function assertLengthAtLeast<
  T extends readonly any[],
  I extends 0 | 1 | 2 | 3 | 4,
>(array: T, length: I): array is T & LengthAtLeast<T, I> {
  return array.length >= length;
}

export function isArrayOfMinLength<I extends 0 | 1 | 2 | 3 | 4>(
  input: unknown,
  length: I,
): input is LengthAtLeast<unknown[], I> {
  if (!Array.isArray(input)) {
    return false;
  }
  if (input.length < length) {
    return false;
  }
  return true;
}
